<template>
   <div class="cont not-check-left">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>系统管理</el-breadcrumb-item>
            <el-breadcrumb-item>日志管理</el-breadcrumb-item>
            <el-breadcrumb-item>操作日志</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-flex-box">
         <!-- 左表格 -->
         <el-col :span="6" class="left-table-bg">
            <el-row class="left-table">
               <el-table
                     ref="sideTable"
                     v-loading="sideLoad"
                     :data="sideData"
                     border fit
                     @row-click="(row) => handleCurrentChange(row)"
                     row-key="id"
                     style="width: 100%;"
                     max-height="780"
                     :stripe="true"
                     size="medium">
                  <el-table-column prop="name" :label="$t('msg.menu_list')"></el-table-column>
               </el-table>
            </el-row>
         </el-col>
         <!-- 右表格 -->
         <el-col :span="18" class="right-table-bg">
            <el-row class="right-table">
               <!-- 搜索框 -->
               <el-row class="search-box">
                  <el-row class="search-row">
                     <el-row class="search-item">
                        <label>用户名：</label>
                        <el-input class="width-220"  v-model="param.userName" placeholder="用户名" clearable></el-input>
                     </el-row>
                     <el-row class="search-item">
                        <label>操作时间：</label>
                        <date-packer class="width-220"  :datePacker="dateValue" @setDatePacker="getDatePacker"></date-packer>
                     </el-row>
                  </el-row>
                  <el-row class="search-row">
                     <el-row class="search-item">
                        <label>日志类型：</label>
                        <el-select class="width-220" v-model="param.loggerType" :placeholder="$t('msg.log_type')">
                           <el-option v-for="(item, index) in dictData['operate-type']"
                                      :key="index"
                                      :label="item"
                                      :value="index">
                           </el-option>
                        </el-select>
                     </el-row>
                     <el-row class="search-item">
                        <label>状态：</label>
                        <el-button-group class="m-right-10">
                           <el-button  :type="param.state === '' ? 'primary' : ''" @click="param.state = ''" ><span>全部</span></el-button>
                           <el-button  :type="param.state === 'ENABLED' ? 'primary' : ''" @click="param.state = 'ENABLED'" ><span v-text="$t('msg.success')">成功</span></el-button>
                           <el-button  :type="param.state === 'DISABLED' ? 'primary' : ''" @click="param.state = 'DISABLED'" ><span v-text="$t('msg.fail')">失败</span></el-button>
                        </el-button-group>
                     </el-row>
                     <el-button   type="primary" icon="el-icon-search"  @click="handleQuery(true)"><span v-text="$t('msg.search')">搜索</span></el-button>
                     <el-button   type="primary" icon="el-icon-refresh-right" plain @click="handleQuery(false)"><span v-text="$t('msg.reset')">重置</span></el-button>
                  </el-row>
               </el-row>
               <!-- 表格 -->
               <el-row class="table-box">
                  <el-table
                        :data="tableData"
                        v-loading="loading"
                        border fit
                        min-height="780"
                        :stripe="true"
                        style="width: 100%">
                     <el-table-column type="expand">
                        <template slot-scope="props">
                           <el-form label-position="left" inline class="demo-table-expand">
                              <el-form-item :label="$t('msg.ip_address_a')">
                                 <span>{{ props.row.ipAddress }}</span>
                              </el-form-item>
                              <el-form-item :label="$t('msg.operating_system_a')">
                                 <span>{{ props.row.system }}</span>
                              </el-form-item>
                              <el-form-item :label="$t('msg.browser_a')">
                                 <span></span>
                              </el-form-item>
                              <el-form-item label="URL:">
                                 <span>{{ props.row.url }}</span>
                              </el-form-item>
                              <el-form-item :label="$t('msg.operation_duration_a')">
                                 <span>{{ props.row.longTime }}</span>
                              </el-form-item>
                              <el-form-item :label="$t('msg.operation_method_a')">
                                 <span>{{ props.row.handlerMethod }}</span>
                              </el-form-item>
                              <el-form-item :label="$t('msg.parameter_a')">
                                 <span>{{ props.row.params }}</span>
                              </el-form-item>
                              <el-form-item :label="$t('msg.detailed_info_a')">
                                 <span>{{ props.row.remark }}</span>
                              </el-form-item>
                           </el-form>
                        </template>
                     </el-table-column>
                     <el-table-column label="序号" width="60">
                        <template slot-scope="scope">
                           {{scope.$index + 1}}
                        </template>
                     </el-table-column>
                     <el-table-column :label="$t('msg.user_name')" prop="userName"></el-table-column>
                     <el-table-column :label="$t('msg.nickname')" prop="nickName" min-width="100"></el-table-column>
                     <el-table-column :label="$t('msg.menu_name')" prop="menuName" min-width="80"></el-table-column>
                     <el-table-column :label="$t('msg.operation_name')" prop="handlerName" min-width="160"></el-table-column>
                     <el-table-column :label="$t('msg.log_type')">
                        <template slot-scope="scope">
                           {{ scope.row.loggerType | filterLogType(that) }}
                        </template>
                     </el-table-column>
                     <el-table-column :label="$t('msg.status')">
                        <template slot-scope="scope">
                           {{ scope.row.state | filterState(that) }}
                        </template>
                     </el-table-column>
                     <el-table-column :label="$t('msg.operate_time')" prop="createTime"></el-table-column>
                     <el-table-column :label="$t('msg.detailed_info')" show-overflow-tooltip prop="remark"></el-table-column>
                  </el-table>
                  <!-- 分页 -->
                  <pagination :total="total" :page-size="param.limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
               </el-row>
            </el-row>
         </el-col>
      </el-row>
   </div>
</template>

<script>
   import { mapState } from 'vuex'
   import { urlObj } from '@/api/interface'
   import { system } from '@/api/interface/system'
   import {getDict} from '@/common/js/common'
   export default {
      data(){
         return{
            that: this,
            tableData: [],
            sideData: [],
            boxIcon: true,       // 侧边栏折叠状态
            sideLoad: true,      // 侧边栏加载动画
            loading: true,       // 主体表格加载动画
            dateValue: [],       // 操作时间段
            total: 0,            // 数据总数
            param: {
               userName: '',
               beginCreateTime: '',
               endCreateTime: '',
               loggerType: '',
               state: '',
               menuId: '',
               page: 1,
               limit: 0
            }
         }
      },
      computed: {
         ...mapState(['dictData'])
      },
      mounted() {
         getDict(['operate-type', 'data-status'])
         this.param.limit = sessionStorage.getItem('pageSize') * 1
         this.getOperateLog()
         this.getMenus()
      },
      methods: {
         // 获取操作日志列表
         getOperateLog(){
            const param = { ...this.param }
            const url = system.operateLogPaging
            this.$axios.post(url, param).then(async res  => {
               if(res.searchCount){
                  this.total = res.total
                  this.loading = false
                  this.tableData = res.records
               }
            })
         },
         // 获取操作时间段
         getDatePacker(val){
            this.dateValue = val
            this.param.beginLoginTime = val[0]
            this.param.endLoginTime = val[1]
         },
         // 搜索功能
         handleQuery(bool){
            this.param.page = 1
            if (bool) return this.getOperateLog()
            this.param.userName = ''
            this.param.loggerType = ''
            this.param.state = ''
            this.getDatePacker([])
         },
         // 重置搜索日志
         handleReset(){
            this.param.userName = ''
            this.dateValue = []
            this.param.loggerType = ''
            this.param.state = ''
         },
         // 改变显示条数
         pageChange(num){
            this.param.limit = num
            this.getOperateLog()
         },
         // 改变当前页
         handlePaging(num){
            this.param.page = num
            this.getOperateLog()
         },
         // 获取菜单列表
         getMenus(){
            const url = system.menu
            this.$axios.post(url,{}).then((res) => {
               if(res.success) this.sideLoad = false; this.sideData = res.children
            })
         },
         // 获取侧边栏当前点击对象
         handleCurrentChange: (() => {
            let id
            return function (row) {
               if (row.id !== id){
                  id = row.id
                  this.param.menuId = row.id
               }else {
                  id = ''
                  this.param.menuId = ''
                  this.$refs.sideTable.setCurrentRow()
               }
               this.getOperateLog()
            }
         })(),
      },
      filters: {
         filterLogType(val, that) {
            if (val) return that.dictData['operate-type'][val]
         },
         filterState(val, that) {
            if (val) return that.dictData['data-status'][val]
         }
      }
   }
</script>

<style scoped>
    .el-table{
        font-size: 12px;
    }
    .demo-table-expand {
        font-size: 0;
    }
    .demo-table-expand label {
        width: 90px;
        color: #99a9bf;
    }
    .demo-table-expand .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
        width: 50%;
        white-space: normal;
        word-wrap: break-word;
        word-break: break-all;
    }
</style>
